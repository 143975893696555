<template>
  <profiles-index :filters="filters" :fields="fields" />
</template>

<script>
import Vue from 'vue';
import ProfilesIndex from '~/pages/shared/profiles/ProfilesIndex';

export default Vue.extend({
  components: { ProfilesIndex },

  middleware: 'employee',

  data: () => ({
    fields: ['basic', 'created_at', 'actions'],
  }),
});
</script>
